.wrapper
    display: flex
    align-items: center

.checkCode
    padding-top: 8px

.login
    display: flex
    align-items: center

.lkButtonWrapper
    padding-bottom: 8px


.buttonWrapper
    cursor: pointer
    white-space: nowrap

    @media only screen and (max-width: 1200px)
       display: none

.buttonWrapperBf
    cursor: pointer
    white-space: nowrap
    color: #FFB500 !important

    @media only screen and (max-width: 1200px)
       display: none 

.button
    margin-left: 4px

    &Bf
        border: none
        color: #000
        background-color: #FFB500 
        font-weight: bold
        border-radius: 4px
        padding: 8px 16px
        cursor: pointer
        text-align: center
        transition: all 0.2s

.buttonGap
    margin-left: 0

.favorite
    display: flex
    align-items: center
    margin-right: 24px
    text-decoration: none

    &Text
        color: var(--primary-color)
        font-weight: bold
        font-size: 14px
        line-height: 20px
        margin-left: 10px

        @media only screen and (max-width: 1200px)
            display: none


.favoriteTextBf
    color: #FFB500 !important
    font-weight: bold
    font-size: 14px
    line-height: 20px
    margin-left: 10px

.favoriteText
    white-space: nowrap

.heart
    position: relative

    span
        position: absolute
        right: -5px
        top: -3px
        padding: 0 4px
        border-radius: 50%
        background: var(--error-color)
        color: #FFFFFF
        font-weight: bold
        font-size: 10px
        line-height: 14px

.loginContentButton
    display: flex
    align-items: center

.loginContentAvatar
    padding-right: 16px

    @media only screen and (max-width: 1200px)
        padding-right: 0

.contactsWhatsApp
    display: flex
    align-items: center
    gap: 8px

.captcha
    margin-top: 16px

.disabled
    color: #616770

@media (max-width: 600px)
    .wrapper
        margin-left: 16px
    .favoriteText
        display: none

    .favorite
        margin-right: 0

