.wrapper
    flex: 0 0 auto
    position: fixed
    width: 100%
    height: 105px
    display: grid
    z-index: 60
    grid-template-rows: 48px 40px
    padding-bottom: 15px

.wrapperBlackFriday
    flex: 0 0 auto
    position: fixed
    width: 100%
    height: 105px
    display: grid
    z-index: 60
    grid-template-rows: 48px 40px
    // padding-bottom: 15px
    // background-color: #000000

.textWrapper
    padding-left: 4px

.topButton
    font-size: 20px
    font-weight: 700
    line-height: 24px
    display: flex
    text-decoration: none

.header
    display: grid
    grid-template-columns: 170px 1fr
    grid-gap: 10px

    &Top
        margin-top: 16px

.logo
    margin-top: 20px
    display: flex
    justify-content: start

.contactsBar
    background-color: #FFF
    display: flex
    align-items: center
    margin-top: 8px

    &Bf
        background-color: #000 !important

.headerNav
    background: #fff
    display: flex
    align-items: center
    height: 78px

.headerNavBf
    background: #000000 !important
    display: flex
    align-items: center
    height: 78px

.menu
    margin-left: 28px
    display: flex
    align-items: center
    padding-bottom: 16px
    margin-top: 0
    margin-bottom: 0

.menu:hover .menuItem:not(:hover)
    opacity: 0.5

.allCity
    display: flex

.menuItem
    margin: 6px
    transition: opacity 0.3s
    position: relative
    white-space: nowrap

.menuItemBf
    margin: 6px
    transition: opacity 0.3s
    position: relative
    white-space: nowrap    
    color: #ffffff !important

.menuItemBf:hover .dropDownMenuBf
    display: block

.dropDownMenuBf
    position: fixed
    padding-top: 42px
    padding-bottom: 48px
    left: 0
    width: 100%
    background-color: #000000!important
    box-shadow: 0 40px 40px -40px rgba(0, 0, 0, 0.16)
    display: none


.menuItem:hover .dropDownMenu
    display: block

.actions
    margin-left: auto
    position: relative
    z-index: 1

.dropDownMenu
    position: fixed
    padding-top: 42px
    padding-bottom: 48px
    left: 0
    width: 100%
    background-color: #ffffff
    box-shadow: 0 40px 40px -40px rgba(0, 0, 0, 0.16)
    display: none

.dropDownMenuWrapper
    display: grid
    width: 100%
    grid-template-columns: 1fr 1fr 1fr

.dropDownMenuFeatures
    margin-top: 8px


.dropDownMenuFeature
    margin-bottom: 7.2px


.colorText
    color: #fff
    &:hover
        color: #FFB500!important


.timeTable
    display: flex
    min-width: 251px
    margin-left: 34px

.agentEntry
    padding: 0
    border: none
    cursor: pointer

.InTimeTable
    display: flex
    align-items: center

.timeTableNone
    text-decoration: none

.season
    display: flex
    gap: 60px
    margin-top: 16px

    &Bf
        color: #fff
        &:hover
            color: #FFB500!important

    &Item
        &:hover
            color: #026ED9

.arrowRight
    margin-left: 8px  

.iconsWrapper
    display: flex
    align-items: center
    margin-left: 18px

.linkWrapper
    display: flex
    margin-top: 32px

    &Bf
        color: #fff
        &:hover
            color: #FFB500!important


.seasonItemWrapper
    display: flex

.ItemWrapper
    display: flex

.wishes
    color: #818894 !important


.infoWrapper
    display: flex

    &Bf
        color: #fff
        &:hover
            color: #FFB500!important


@media (max-width: 600px)

    .headerNav
        padding-top: 16px
        padding-bottom: 8px

