.wrapper
    width: 288px
    position: absolute
    right: 0
    top: 50px
    background: #FFFFFF
    border: 1px solid rgba(0, 0, 0, 0.15)
    box-shadow: 0 4px 24px rgb(0 0 0 / 10%)
    border-radius: 4px
    padding: 22px 16px 24px 16px

.wrapperBf
    width: 288px
    position: absolute
    right: 0
    top: 50px
    background: #000000
    border: 1px solid rgba(153, 153, 153, 0.15)
    box-shadow: 0 4px 24px rgb(0 0 0 / 10%)
    border-radius: 4px
    padding: 22px 16px 24px 16px

.textItem
    color: #fff

    &:hover
        color:#FFB500
.item
    display: grid
    grid-gap: 16px

.buttonWrapper
    cursor: pointer

.orders
    display: flex
    justify-content: space-between
    align-items: center

    &Count
        background: #F3F6F9
        border-radius: 12px
        padding: 2px 8px

@media (max-width: 500px)
    .wrapper
        width: 254px

